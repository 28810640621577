import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { connect } from 'react-redux'

/*
 * This component is built using `gatsby-image` to automatically serve
 * optimized images with lazy loading and reduced file sizes. The image is
 * loaded using a `StaticQuery`, which allows us to load the image from
 * directly within this component, rather than having to pass the image data
 * down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Image = ({
  isFetching
}) => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "IMG_1254.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img
      imgStyle={{
        backgroundColor: isFetching ? `red` : `white`
      }}
      fluid={data.placeholderImage.childImageSharp.fluid}
    />}
  />
)

const mapStateToProps = (state) => {
  return {
    isFetching: state.counter.isFetching
  }
}

const ConnectedImage = connect(
  mapStateToProps
)(Image)

export default ConnectedImage
