import React from "react"
// import { Link } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Counter from '../components/counter'

const IndexPage = () => (
  <Layout>
    <SEO title="Hello, World!" keywords={[`gatsby`, `application`, `react`]} />
    <h1>Hello, World!</h1>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>

    <Counter />
   {/* <Link to="/page-2/">Go to page 2</Link> */}
  </Layout>
)

export default IndexPage
