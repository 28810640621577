import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  increment,
  incrementIfOdd,
  decrement ,
  incrementAsync
} from '../lib/actions'

const Counter = ({
  value,
  isFetching,
  onIncrement,
  onIncrementAsync,
  onDecrement,
  onIncrementIfOdd
}) => (
  <p>
    Clicked: <strong>{value}</strong> times <button onClick={onIncrement}>+</button> <button onClick={onDecrement}>-</button>{' '}
    <button onClick={onIncrementIfOdd}>Increment if odd</button>{' '}
    <button onClick={onIncrementAsync}>Increment async</button>{' '}
    { isFetching ?
      <span role="img" aria-label="hamburger">&#x1F354; fetching...</span> :
      '' }
  </p>
)

Counter.propTypes = {
  value: PropTypes.number.isRequired,
  onIncrement: PropTypes.func.isRequired,
  onDecrement: PropTypes.func.isRequired,
  onIncrementAsync: PropTypes.func.isRequired,
  onIncrementIfOdd: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    value: state.counter.value,
    isFetching: state.counter.isFetching
  }
}

// mapDispatchToProps binds action creator functions so that you don’t have to
// write props.dispatch(actionName()) all over the place, you can just write
// props.actionName().
//
const mapDispatchToProps = {
  onIncrement: increment,
  onDecrement: decrement,
  onIncrementAsync: incrementAsync,
  onIncrementIfOdd: incrementIfOdd
}

const ConnectedCounter = connect(
  mapStateToProps,
  mapDispatchToProps
)(Counter)

export default ConnectedCounter
